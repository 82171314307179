import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { EmailSetup } from "../Pages/RoleEmailPage";
import {
  CheckCircle,
  HourglassBottomTwoTone,
  HourglassEmpty,
  Replay,
  SendRounded,
} from "@mui/icons-material";

export default function SendEmail({ selectedIds, eventId, role }) {
  const [open, setOpen] = React.useState(false);
  const close = () => setOpen(false);
  return (
    <>
      <Dialog fullWidth maxWidth="md" open={open} onClose={close}>
        <DialogTitle>Send Email</DialogTitle>
        <DialogContent>
          <Box p={2}>
            <ConfigureEmailFor {...{ selectedIds, eventId, role }} />
          </Box>
        </DialogContent>
      </Dialog>

      {selectedIds.length > 0 && (
        <Button variant="contained" color="error" onClick={() => setOpen(true)}>
          Send Mail ({selectedIds.length})
        </Button>
      )}
    </>
  );
}

function ConfigureEmailFor({ eventId, role, selectedIds }) {
  const { data, loading } = useQuery(GET_EMAIL, {
    variables: { eventId, role },
    onError: (e) => console.log(JSON.stringify(e, null, 2)),
  });
  const [step, setStep] = React.useState(0);
  const [email, setEmail] = React.useState({});
  const handleSubmit = (f) => {
    const {
      senderName,
      senderEmail,
      senderEmailPassword,
      senderHost,
      senderSubject,
      senderBody,
      enquiryBody,
      to,
    } = f;
    setEmail({
      senderName,
      senderEmail,
      senderEmailPassword,
      senderHost,
      senderSubject,
      senderBody,
      enquiryBody,
      to,
    });
    setStep((s) => s + 1);
  };
  return (
    <Container>
      <Stepper activeStep={step}>
        <Step>
          <StepLabel>Configure Email</StepLabel>
        </Step>
        <Step>
          <StepLabel>Sent</StepLabel>
        </Step>
        {/* <Step>
          <StepLabel>Report</StepLabel>
        </Step> */}
      </Stepper>
      <Box p={2} />
      {step === 0 ? (
        <>
          {loading ? (
            <LinearProgress />
          ) : (
            <EmailSetup
              title=""
              onSubmit={handleSubmit}
              defaultValues={data?.email}
            />
          )}
        </>
      ) : (
        <SendingEmailList
          {...{ email, selectedIds, eventId, role }}
          onDone={() => setStep((s) => s + 1)}
        />
      )}
    </Container>
  );
}

function SendingEmailList({ email, selectedIds, eventId, role, onDone }) {
  const [done, setDone] = React.useState(0);
  // const MemoItem = React.memo(Item, (prev, next) => prev.id === next.id);
  const handleDone = () => {
    setTimeout(() => {
      setDone((s) => s + 1);
    }, 7000);
  };

  React.useEffect(() => {
    if (done === selectedIds?.length) {
      onDone();
    }
  }, [done]);

  return (
    <>
      <List>
        {selectedIds?.map(({ id, email: address }, key) => (
          <Item
            key={key}
            {...{ email, eventId, role, id, address }}
            onDone={handleDone}
            allowSend={done === key}
          />
        ))}
      </List>
    </>
  );
}

const Item = React.memo(function ({
  id,
  address,
  onDone,
  email,
  eventId,
  role,
  allowSend,
}) {
  // console.log(id);

  const [send, { data, loading, error }] = useMutation(SEND_EMAIL, {
    variables: {
      eventId,
      role,
      email,
      id,
    },
  });
  React.useEffect(() => {
    if (!allowSend) return;

    const t = setTimeout(() => {
      console.log("sending", id);
      send().then(() => onDone(id));
      // onDone(id);
    }, 300);
    return () => clearTimeout(t);
  }, [allowSend]);

  if (loading) {
    return (
      <ListItem>
        <ListItemText primary={id} secondary={address} />
        <ListItemIcon>
          <HourglassBottomTwoTone />
        </ListItemIcon>
      </ListItem>
    );
  }
  if (data?.result) {
    return (
      <ListItem>
        <ListItemText primary={id} secondary={address} />
        <ListItemIcon>
          <CheckCircle color="success" />
        </ListItemIcon>
      </ListItem>
    );
  }
  return (
    <ListItemButton onClick={() => send()}>
      <ListItemText primary={id} secondary={address} />
      {error && (
        <Box
          sx={{
            maxWidth: 400,
          }}
        >
          {error.message}
        </Box>
      )}
      <ListItemIcon>
        <Replay color="error" />
      </ListItemIcon>
    </ListItemButton>
  );
});

const GET_EMAIL = gql`
  query ($eventId: String!, $role: ROLE!) {
    email(eventId: $eventId, role: $role) {
      id
      role
      senderName
      senderEmail
      senderEmailPassword
      senderHost
      senderSubject
      senderBody
      enquiryBody
      to
      created
      modified
    }
  }
`;

const SEND_EMAIL = gql`
  mutation (
    $eventId: String!
    $role: ROLE!
    $email: EmailInput!
    $id: String!
  ) {
    result: sendEmail(eventId: $eventId, role: $role, email: $email, id: $id)
  }
`;
