import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const [login, { loading, error }] = useMutation(gql`
    mutation ($username: String!, $password: String!) {
      token: login(username: $username, password: $password)
    }
  `);

  const navigate = useNavigate();

  const onLogin = async (variables) => {
    return login({ variables }).then((resp) => {
      localStorage.setItem("auth.admin.eventegration.com", resp.data.token);
      navigate("/events");
    });
  };

  return (
    <Container maxWidth="sm">
      <Paper sx={{ p: 2, mt: 10 }} variant="outlined">
        <Typography align="center" variant="h5" gutterBottom>
          Admin Control Panel
        </Typography>
        <LoginForm onSubmit={onLogin} />

        {loading && <LinearProgress sx={{ mt: 2 }} />}

        {!!error && (
          <motion.div
            animate={{
              x: [30, -30, 15, -15, 8, 0],
            }}
            transition={{ duration: 0.4 }}
          >
            <Typography color="error" align="center" sx={{ mt: 2 }}>
              {error.message}
            </Typography>
          </motion.div>
        )}
      </Paper>
    </Container>
  );
}

export function LoginForm({ onSubmit }) {
  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Controller
          control={control}
          name="username"
          rules={{
            maxLength: 20,
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              {...field}
              inputRef={ref}
              helperText={error?.message}
              error={!!error}
              label="Username"
              autoComplete="new-password"
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required: "Required",
          }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              {...field}
              inputRef={ref}
              helperText={error?.message}
              error={!!error}
              label="Password"
              type="password"
            />
          )}
        />

        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={formState.isSubmitting}
        >
          Enter
        </LoadingButton>
      </Stack>
    </form>
  );
}
