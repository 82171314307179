import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { EventForm } from './AddPaymentMethod';
import FileCopyIcon from '@mui/icons-material/FileCopy';

export default function PaymentCard({ detail, eventId, role, refresh }) {
    console.log(">>>>>>>>>>>>>", eventId);
    const [open, setOpen] = React.useState(false);
    const [copied, setCopied] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    };
    const theme = useTheme();
    const handleEdit = () => {
        handleClickOpen()
    }
    const handleDelete = async () => {
        await deletePaymentOption({
            variables: {
                eventId,
                id: detail.id,
            },
        });
        setTimeout(() => {
            refresh()
        }, 500);
    }
    const [updatePaymentOption] = useMutation(UPDATE_PAYMENT_OPTION);
    const [deletePaymentOption] = useMutation(DELETE_PAYMENT_OPTION);

    const renderText = (field, text) => {
        if(!text)  return;
        const displayText = text.length > 12 ? `${text.slice(0, 12)} xx` : text;
        return (
            <Typography variant="subtitle1" color="text.secondary" fontWeight="bold" component="div">
                {field}:{' '}
                <Typography variant="subtitle1" color="text.secondary" fontWeight={300} component="span">
                    {displayText}
                </Typography>
                {text.length > 12 && <IconButton onClick={() => handleCopy(text)}><FileCopyIcon /></IconButton>}
            </Typography>
        );
    };




    const onSubmit = async (formData, event) => {
        try {
            event.preventDefault();
            const inp = { id: detail.id, ...formData };
            await updatePaymentOption({
                variables: {
                    eventId,
                    role,
                    inp: JSON.stringify(inp),
                },
            });
            setTimeout(() => {
                handleClose();
                refresh()
            }, 500);
        } catch (error) {
            console.error('Error submitting form:', error?.message);
        }
    };
    return (
        <>
            <Card sx={{ display: 'flex', flexDirection: "column" }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography component="div" variant="h5" textTransform={"capitalize"}>
                            {detail.paymentMethod}
                        </Typography>
                        <Typography component="div" variant="body1" fontWeight={"bold"} textTransform={"capitalize"}>
                            Currency: {detail.currency}
                        </Typography>
                        </Stack>
                        <Divider variant="horizontal" sx={{ mt: 2 }} />
                        {renderText('API Key/Client Id', detail.apiKey)}
                        <Divider variant="horizontal" />
                        {renderText('Secret Key', detail.secretKey)}
                        <Divider variant='horizontal' />
                        {renderText('Public Key', detail.publicKey)}
                        <Divider variant='horizontal' />
                        {renderText('API URL', detail.apiurl)}
                        <Divider variant='horizontal' />
                    </CardContent>

                </Box>
                <Box>
                    <Stack direction="row" justifyContent="space-between" spacing={2} margin={2} >
                        <Button variant='contained' color='warning' onClick={handleEdit}>Edit</Button>
                        <Button variant='contained' color='error' onClick={handleDelete}>Delete</Button>
                    </Stack>
                </Box>
            </Card>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Add Payment Method</DialogTitle>
                <DialogContent>
                    <EventForm onSubmit={onSubmit} editValues={detail} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export const UPDATE_PAYMENT_OPTION = gql`
  mutation ($eventId: String!, $role: ROLE!, $inp:String!) {
    updatePaymentOption(eventId: $eventId, role: $role, inp:$inp) 
  }
`;
export const DELETE_PAYMENT_OPTION = gql`
  mutation ($eventId: String!, $id:Int!) {
    deletePaymentOption(eventId: $eventId,id:$id) 
  }
`;