// FeaturePanel.js
import React, { useState, useEffect } from 'react';
import IsVerificationFeatureRequired from './IsVerificationFeatureRequired';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_FEATURE, GET_VERIFICATION_FEATURE } from '../Pages/RoleFormPage';
import { Autocomplete, Box, Button, Chip, Grid, LinearProgress, Stack, TextField, Typography } from '@mui/material';

export default function FeaturePanel({ eventId, role }) {
  const [tags, setTags] = React.useState([]);
  const [savedFeature, setsavedFeature] = useState(false);

  console.log(eventId, role);
  const { data, loading, error,refetch } = useQuery(GET_VERIFICATION_FEATURE, {
    variables: { eventId, role },
  });

  const features = ["emailVerification", "WhatsappRegistration", "ShareButton", "DownloadBadge", "PaymentEnable","Captcha","GeoLocation","DuplicateNotAllowed"];

  const [addFeature] = useMutation(ADD_FEATURE);

  const handleSaveFeature = () => {
    const field = "features";
    addFeature({ variables: { eventId, role, field, value: JSON.stringify(tags) } });
    setsavedFeature(tags)

  };

  const handleTagsChange = (event, newValue) => {
    setTags(newValue);
  };

  useEffect(() => {
    if (data && data?.mailVerificationFeature) {
      let parsedData = [];
      try {
        parsedData = JSON.parse(data.mailVerificationFeature.features);
      } catch (err) {
        console.error("Error parsing the data:", err);
      }
      if (Array.isArray(parsedData)) {
        setTags(parsedData);
        setsavedFeature(parsedData)
      }
    }
  }, [data]);
  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Typography color="error">{error.message}</Typography>;
  }
  return (
    <Box>
      <Typography variant='h5'>Feature Panel</Typography>
      <Grid container >
        <Stack spacing={2} direction={"row"} m={2} sx={{ width: "100%" }}>
          <Autocomplete
            multiple
            freeSolo
            defaultValue={tags}
            options={features}
            value={tags}
            fullWidth
            onChange={handleTagsChange}
            renderTags={(value, getTagProps) =>
              Array.isArray(value) ? value.map((option, index) => (
                <Chip
                  variant="outlined"
                  color="success"
                  label={option}
                  {...getTagProps({ index })}
                />
              )) : null
            }

            renderInput={(params) => (
              <Stack direction="column">
                <TextField
                  {...params}
                  label="Add features in this form"
                  variant="outlined"
                  fullWidth
                />
              </Stack>
            )}
          />
          <Button onClick={handleSaveFeature} variant='contained' color='success'>Save</Button>
        </Stack>
        </Grid>
        <IsVerificationFeatureRequired
            eventId={eventId}
            role={role}
            selectedFeatures={savedFeature}
          />
    </Box>
  );
};
