import { gql, useQuery } from '@apollo/client';
import { Button, Grid, LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import AddPaymentMethod from './AddPaymentMethod';
import PaymentCard from './PaymentCard';

export default function PaymentSetting({ eventId, role }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data: paymentOptions, loading, error, refetch } = useQuery(GET_PAYMENT_OPTION, {
    variables: { eventId, role },
  });
  console.log(paymentOptions);
  let paymentData = []
  if (paymentOptions && paymentOptions?.getPaymentOptions) {
    paymentData = JSON.parse(paymentOptions?.getPaymentOptions)
  }

  if (loading) return <LinearProgress />
  if (error) return <Typography variant='body2' color={'error'} margin={2} textAlign={"center"}>{error.message}</Typography>
  return (

    <Grid container spacing={2} md={12} my={2}>
      <AddPaymentMethod open={open} handleClose={handleClose} eventId={eventId} role ={role} refresh = {refetch} />
      <Stack width={"100%"} direction={"row"} justifyContent={"space-between"} spacing={2}>
        <Typography variant='h4' fontSize={24} fontWeight={"bold"}>Active Payment Options</Typography>
        <Button variant='outlined' color='success' onClick={handleClickOpen}>Add New Payment Option</Button>
      </Stack>
      {
        paymentData.length < 1 ? <Typography variant='body1' > No Payment Option Found</Typography> : (
          paymentData.map((item,i)=>(
          <Grid key = {i} item md={3}>
            <PaymentCard detail={item} eventId={eventId} role ={role} refresh = {refetch}/>
          </Grid>
          )
        )
      )}
      <Grid item md={3}>

      </Grid>
    </Grid>

  )
}

export const GET_PAYMENT_OPTION = gql`
  query ($eventId: String!, $role: ROLE!) {
    getPaymentOptions(eventId: $eventId, role: $role) 
  }
`;