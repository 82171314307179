import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { DataGrid, GridToolbar,GridCellParams } from "@mui/x-data-grid";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { csvToArray } from "../constants";

export default function EventStandsPage() {
  const { eventId } = useParams();
  return (
    <React.Fragment>
      <Typography
        variant="h4"
        sx={{ textTransform: "capitalize" }}
        gutterBottom
      >
        Stands List
      </Typography>

      <AllowColumnforLeadGen eventId={eventId} />
      <StandsTable eventId={eventId} />

    </React.Fragment>
  );
}

function AllowColumnforLeadGen({ eventId }) {
  const [tags, setTags] = React.useState([]);
  const [addgetAllFormField] = useMutation(ADD_ALLOWED_COLUMN);
  const { data: featureData, loading: featureLoading } = useQuery(GET_FORM_FIELDS, {
    variables: { eventId },
  });
  const { data: selectedColumnData, loading: SelectedColumnLoading } = useQuery(GET_SELECTED_FORM_FIELDS, {
    variables: { eventId },
  });


  React.useEffect(() => {
    if (selectedColumnData && selectedColumnData.getSelectedFormField) {
      let parsedData = [];
      try {
        parsedData = selectedColumnData?.getSelectedFormField;
      } catch (err) {
        console.error("Error parsing the data:", err);
      }
      if (Array.isArray(parsedData)) {
        setTags(parsedData);
        console.log("Setting tags to:", parsedData);
      }
    }
  }, [selectedColumnData]);

  const allColumnList = featureData?.getAllFormField;

  const handleTagsChange = (event, newValue) => {
    setTags(newValue);
    addgetAllFormField({ variables: { eventId, value: JSON.stringify(newValue) } });
  };

  if (featureLoading) return <span>Loading...</span>;
  if (SelectedColumnLoading) return <span>Loading...</span>;

  return (
    <Grid item md={12} xs={12}>
      <Autocomplete
        multiple
        freeSolo
        defaultValue={tags}
        options={allColumnList}
        value={tags}
        onChange={handleTagsChange}
        renderTags={(value, getTagProps) =>
          Array.isArray(value) ? value.map((option, index) => (
            <Chip
              variant="outlined"
              color="success"
              label={option}
              {...getTagProps({ index })}
            />
          )) : null
        }

        renderInput={(params) => (
          <Stack direction="column">
            <TextField
              {...params}
              label="Select name of column which you want to display Lead Generation App"
              variant="outlined"
              fullWidth
            />

          </Stack>
        )}
      />
    </Grid>
  );
}

function StandsTable({ eventId }) {
  const { data, loading, refetch } = useQuery(GET_STANDS, {
    variables: { eventId },
  });
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [updateStand] = useMutation(UPDATE_STAND);
  const handleEditCellChange = React.useCallback(
    ({ id, field, props }) => {
      if (props.value !== props.cell.value) {
        updateStand({
          variables: {
            eventId,
            id,
            field,
            value: props.value,
          },
        })
        .then(() => refetch())
        .catch((error) => window.alert(error.message));
      }
    },
    [eventId, updateStand, refetch]
  );

  return (
    <React.Fragment>
      <DataGrid
  loading={loading}
  sx={{ height: 500 }}
  components={{
    LoadingOverlay: LinearProgress,
    Toolbar: GridToolbar,
  }}
  columns={[
    { field: "id", width: 120 },
    { field: "name", width: 200, headerName: "Name", editable: true },
    { field: "parentId", width: 120, headerName: "Parent ID", editable: true },
    { field: "company", width: 200, headerName: "Company Name", editable: true },
    { field: "email", width: 200, headerName: "Email", editable: true },
    { field: "allowed_no_of_staff", width: 120, headerName: "Allowed Staff No", editable: true },
    {
      field: "role",
      width: 100,
      headerName: "Role",
      renderCell: (params) => (
        <Select
          value={params.value}
          onChange={(event) => {
            const newValue = event.target.value;
            if (params.id && newValue !== params.value) {
              updateStand({
                variables: {
                  eventId,
                  id: params.id,
                  field: "role",
                  value: newValue,
                },
              })
                .catch((error) => window.alert(error.message))
                .finally(() => {
                  refetch();
                });
            }
          }}
        >
          <MenuItem value="admin">Admin</MenuItem>
          <MenuItem value="attendee">Attendee</MenuItem>
        </Select>
      ),
    },
    { field: "password", width: 100, headerName: "password", editable: true },
  ]}
  rows={data?.stands || []}
  checkboxSelection
  onSelectionModelChange={(ids) => setSelectedIds(ids)}
  onEditCellChange={handleEditCellChange}
/>
      <Stack direction="row" spacing={2} py={1}>
        <Button onClick={() => refetch()}>Refresh</Button>
        <DeleteStand
          eventId={eventId}
          onDelete={() => refetch()}
          variant="contained"
          color="error"
          ids={selectedIds}
        >
          Delete Selected
        </DeleteStand>
        <AddNewStand
          eventId={eventId}
          onAdd={() => refetch()}
          variant="contained"
          color="success"
        >
          Create New
        </AddNewStand>
        <ImportStands
          eventId={eventId}
          onImport={() => refetch()}
          variant="contained"
          color="warning"
        >
          Import CSV
        </ImportStands>
      </Stack>
    </React.Fragment>
  );
}

function ImportStands({ eventId, onImport, ...props }) {
  const inputRef = React.useRef(null);
  const [uploadStands] = useMutation(UPLOAD_STANDS);

  const handleUpload = (arr) => {
    const stands = arr.map((r) => ({ company: r.company, email: r.email }));
    console.log("handleUpload", stands);
    uploadStands({
      variables: {
        eventId,
        stands,
      },
    })
      .catch((e) => {
        console.log(JSON.stringify(e, null, 2));
        window.alert(e.message);
      })
      .then(onImport);
  };

  return (
    <React.Fragment>
      <input
        type="file"
        accept=".csv"
        ref={inputRef}
        hidden
        onChange={(e) => {
          const [file] = e.target.files;
          if (!file) {
            return;
          }
          const reader = new FileReader();
          reader.onload = (r) => {
            const str = r.target.result;
            if (!str) {
              return;
            }
            const arr = csvToArray(str);
            if (!arr?.length) {
              return;
            }
            handleUpload(arr);
          };
          reader.readAsText(file);
        }}
      />
      <Button onClick={() => inputRef.current.click()} {...props} />
    </React.Fragment>
  );
}

function DeleteStand({ eventId, onDelete, ids, ...props }) {
  const [deleteStands] = useMutation(DELETE_STANDS);

  const handleDelete = () => {
    if (!window.confirm("Are you sure you want to delete")) {
      return;
    }
    deleteStands({ variables: { eventId, ids } })
      .then(onDelete)
      .catch((e) => window.alert(e.message));
  };

  if (!ids?.length) {
    return null;
  }

  return <Button onClick={handleDelete} {...props} />;
}

function AddNewStand({ eventId, onAdd, ...props }) {
  const [addStand] = useMutation(ADD_STAND);
  const [open, setOpen] = React.useState(false);
  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      company: "",
      email: "",
      password: "",
      name: "",
      parentId: "",
      allowed_no_of_staff: "",
      role: ""
    },
  });
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New Stand</DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(async (inp) => {
              addStand({
                variables: {
                  eventId,
                  inp,
                },
              })
                .then(onAdd)
                .catch((r) => window.alert(r.message))
                .finally(() => setOpen(false));
            })}
          >
            <Stack p={1} spacing={1}>
              <Controller
                control={control}
                name="name"
                rules={{ required: "Required" }}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <TextField
                    inputRef={ref}
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    label="Name"
                    fullWidth
                  />
                )}
              />
              <Controller
                control={control}
                name="company"
                rules={{ required: "Required" }}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <TextField
                    inputRef={ref}
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    label="Company"
                    fullWidth
                  />
                )}
              />
              <Controller
                control={control}
                name="email"
                rules={{ required: "Required" }}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <TextField
                    inputRef={ref}
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    label="Email"
                    fullWidth
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                rules={{ required: "Required" }}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <TextField
                    inputRef={ref}
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    label="Password"
                    fullWidth
                  />
                )}
              />
              <Controller
                control={control}
                name="allowed_no_of_staff"
                rules={{ required: "Required" }}
                render={({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => (
                  <TextField
                    inputRef={ref}
                    {...field}
                    error={!!error}
                    helperText={error?.message}
                    label="Set Staff Account Limit"
                    fullWidth
                  />
                )}
              />
              <Controller
                control={control}
                name="role"
                rules={{ required: "Required" }}
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel id="role-label">Role</InputLabel>
                    <Select
                      labelId="role-label"
                      id="role"
                      inputRef={ref}
                      {...field}
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="attendee">Attendee</MenuItem>
                    </Select>
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
              <LoadingButton
                loading={formState.isSubmitting}
                variant="contained"
                type="submit"
              >
                Create
              </LoadingButton>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
      <Button {...props} onClick={() => setOpen(true)} />
    </React.Fragment>
  );
}

const GET_STANDS = gql`
  query ($eventId: String!) {
    stands(eventId: $eventId) {
      id
      email
      company
      password
      created
      name
      parentId
      allowed_no_of_staff
      role
    }
  }
`;

const ADD_STAND = gql`
  mutation ($eventId: String!, $inp: StandInput!) {
    result: createStand(eventId: $eventId, inp: $inp)
  }
`;
const UPLOAD_STANDS = gql`
  mutation ($eventId: String!, $stands: [StandInput]) {
    result: uploadStands(eventId: $eventId, stands: $stands)
  }
`;
const UPDATE_STAND = gql`
  mutation ($eventId: String!, $id: String!, $field: String!, $value: String!) {
    result: updateStand(
      eventId: $eventId
      id: $id
      field: $field
      value: $value
    )
  }
`;

const DELETE_STANDS = gql`
  mutation ($eventId: String!, $ids: [String]!) {
    result: deleteStands(eventId: $eventId, ids: $ids)
  }
`;

const ADD_ALLOWED_COLUMN = gql`
mutation ($eventId: String!, $value: String!) {
  addAllowedColumn(eventId: $eventId, value: $value)
}
`;

export const GET_FORM_FIELDS = gql`
  query ($eventId: String!) {
    getAllFormField(eventId: $eventId) 
  }
`;
export const GET_SELECTED_FORM_FIELDS = gql`
  query ($eventId: String!) {
    getSelectedFormField(eventId: $eventId) 
  }
`;