export const ROLES = [
  "visitor",
  "exhibitor",
  "delegate",
  "speaker",
  "media",
  "organizer",
  "crew",
  "workshop",
  "vip",
  "sponsor",
  "committee",
  "CTF",
  "CrisisSimulation",
  "staff",
  "volunteer",
  "survey"
];
