import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { GET_VERIFICATION_FEATURE } from "./RoleFormPage";

export default function RoleEmailPage() {
  const { eventId, role } = useParams();
  const { data: formData } = useQuery(GET_FORM, {
    variables: { eventId, role },
  });
  const { data: emailsData, error } = useQuery(GET_EMAILS, {
    variables: { eventId },
  });
  const [saveEmail] = useMutation(SAVE_EMAIL);

  const onSave = async (r, { __typename, role, ...f }) => {
    saveEmail({
      variables: {
        eventId,
        role: r,
        inp: f,
      },
    })
      .then((res) => alert(res.data.saveEmail))
      .catch((e) => alert(JSON.stringify(e, null, 2)));
  };
  return (
    <Box>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textTransform: "capitalize" }}
      >
        Email Setup For {role}
      </Typography>
      {!!error && (
        <Typography color="error">
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Typography>
      )}
      <Stack
        direction="row"
        spacing={4}
        width="100%"
        divider={<Divider flexItem orientation="vertical" />}
      >
        <Stack spacing={1} divider={<Divider />} width={400}>
          <Typography variant="h6">Variables:</Typography>
          <Typography>$ID</Typography>
          <Typography>$ROLE</Typography>
          <Typography>$EVENT_TITLE</Typography>
          <Typography>$BADGE_URL</Typography>
          <Typography>$CREATED</Typography>
          {formData?.form?.fields
            ?.map((f) => "$" + f.name)
            .map((v) => (
              <Typography>{v}</Typography>
            ))}
        </Stack>
        <Stack spacing={2} divider={<Divider />} width={800}>
          <EmailSetup
            onSubmit={(f) => onSave(role, f)}
            title={`On Register (To ${role})`}
            defaultValues={emailsData?.emails?.find((em) => em.role === role)}
            eventId={eventId}
            role={role}
          />
        </Stack>
      </Stack>
      <Box p={4} />
    </Box>
  );
}

export function EmailSetup({ title, onSubmit, defaultValues, eventId, role }) {
  const [feature, setFeature] = React.useState(null)
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      senderName: "",
      senderEmail: "",
      senderEmailPassword: "",
      senderHost: "",
      senderSubject: "",
      senderAcceptSubject: "",
      senderRejectSubject: "",
      senderBody: "",
      senderAcceptBody: "",
      senderRejectBody: "",
      enquiryBody: "",
      to: "",
    },
  });
  const { data: featureData, loading: featureLoading } = useQuery(GET_VERIFICATION_FEATURE, {
    variables: { eventId, role },
  });

  React.useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  React.useEffect(() => {
    if (featureData && featureData.mailVerificationFeature) {
      let parsedData = [];
      try {
        parsedData = JSON.parse(featureData.mailVerificationFeature.features);
      } catch (err) {
        console.error("Error parsing the data:", err);
      }
      if (Array.isArray(parsedData)) {
        setFeature(parsedData);
      }
    }
  }, [featureData]);
  if (featureLoading) return <span>Loading...</span>;
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={1} my={1}>
        <Typography fontWeight={"bold"}>{title}</Typography>
        <Stack spacing={1}>
          <Controller
            control={control}
            name="senderName"
            rules={{ required: "Required" }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                inputRef={ref}
                {...field}
                required
                error={!!error}
                helperText={error?.message}
                fullWidth
                label="Sender Name"
              />
            )}
          />
          <Controller
            control={control}
            name="senderEmail"
            rules={{ required: "Required" }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                inputRef={ref}
                {...field}
                required
                error={!!error}
                helperText={error?.message}
                fullWidth
                label="Sender Email"
              />
            )}
          />
          <Controller
            control={control}
            name="senderEmailPassword"
            rules={{ required: "Required" }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                inputRef={ref}
                {...field}
                required
                error={!!error}
                helperText={error?.message}
                fullWidth
                label="Sender Email Password"
              />
            )}
          />
          <Controller
            control={control}
            name="senderHost"
            rules={{ required: "Required" }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                inputRef={ref}
                {...field}
                required
                error={!!error}
                helperText={error?.message}
                fullWidth
                label="Sender Host"
              />
            )}
          />
          {feature?.includes("emailVerification") && (
            <>
              <Controller
                control={control}
                name="senderAcceptSubject"
                rules={{ required: "Required" }}
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <TextField
                    inputRef={ref}
                    {...field}
                    required
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                    label="Accept Mail Subject"
                  />
                )}
              />
              <Controller
                control={control}
                name="senderRejectSubject"
                rules={{ required: "Required" }}
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <TextField
                    inputRef={ref}
                    {...field}
                    required
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                    label="Reject Mail Subject"
                  />
                )}
              />
            </>
          )}
          <Controller
            control={control}
            name="senderSubject"
            rules={{ required: "Required" }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                inputRef={ref}
                {...field}
                required
                error={!!error}
                helperText={error?.message}
                fullWidth
                label="Subject"
              />
            )}
          />
          <Controller
            control={control}
            name="senderBody"
            rules={{ required: "Required" }}
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                inputRef={ref}
                {...field}
                required
                error={!!error}
                helperText={error?.message}
                fullWidth
                label="Body"
                multiline
                minRows={4}
              />
            )}
          />
          {feature?.includes("emailVerification") && (
            <>
              <Controller
                control={control}
                name="senderAcceptBody"
                rules={{ required: "Required" }}
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <TextField
                    inputRef={ref}
                    {...field}
                    required
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                    label="Accept Mail Body"
                    multiline
                    minRows={4}
                  />
                )}
              />
              <Controller
                control={control}
                name="senderRejectBody"
                rules={{ required: "Required" }}
                render={({ field: { ref, ...field }, fieldState: { error } }) => (
                  <TextField
                    inputRef={ref}
                    {...field}
                    required
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                    label="Reject Mail Body"
                    multiline
                    minRows={4}
                  />
                )}
              />
            </>
          )}

          <Divider />

          <Controller
            control={control}
            name="to"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                inputRef={ref}
                {...field}
                required
                error={!!error}
                helperText={error?.message}
                fullWidth
                label="To (Comma separated list)"
              />
            )}
          />
          <Controller
            control={control}
            name="enquiryBody"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                inputRef={ref}
                {...field}
                required
                error={!!error}
                helperText={error?.message}
                fullWidth
                label="Enquiry Body"
                multiline
                minRows={4}
              />
            )}
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <LoadingButton
            loading={formState.isSubmitting}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save
          </LoadingButton>
          <Button color="error" onClick={() => reset()}>
            Reset
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

const GET_FORM = gql`
  query ($eventId: String!, $role: ROLE!) {
    form(eventId: $eventId, role: $role) {
      fields {
        name
      }
    }
  }
`;

const GET_EMAILS = gql`
  query ($eventId: String!) {
    emails(eventId: $eventId) {
      role
      senderName
      senderEmail
      senderEmailPassword
      senderHost
      senderSubject
      senderAcceptSubject
      senderRejectSubject
      senderBody
      senderAcceptBody
      senderRejectBody
      enquiryBody
      to
    }
  }
`;

const SAVE_EMAIL = gql`
  mutation ($eventId: String!, $role: ROLE!, $inp: EmailInput!) {
    saveEmail(eventId: $eventId, role: $role, inp: $inp)
  }
`;
