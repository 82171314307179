import { gql, useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  LinearProgress,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../Roles";

export default function CreateNewEventPage() {
  const [create, { loading, error }] = useMutation(CREATE_EVENT);
  const [addRole] = useMutation(ADD_ROLE);
  const navigate = useNavigate();
  const handleSubmit = async (f) => {
    create({
      variables: {
        name: f.name,
      },
    })
      .then(async (res) => {
        const eventId = res.data.event.id;
        for (const role of f.roles) {
          await addRole({ variables: { eventId, role } });
        }
      })
      .then(() => {
        navigate("/events");
      });
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Create New Event
      </Typography>
      <Paper
        sx={{ p: 2, maxWidth: (t) => t.breakpoints.values.md }}
        variant="outlined"
      >
        <EventForm onSubmit={handleSubmit} />
        {loading && <LinearProgress />}
        {!!error && (
          <motion.div
            animate={{
              x: [30, -30, 15, -15, 8, 0],
            }}
            transition={{ duration: 0.4 }}
          >
            <Typography color="error" align="center" sx={{ mt: 2 }}>
              {error.message}
            </Typography>
          </motion.div>
        )}
      </Paper>
    </div>
  );
}

function EventForm({ onSubmit }) {
  const { control, formState, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: "",
      roles: ["visitor", "exhibitor"],
    },
  });
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="name"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <TextField
                {...field}
                inputRef={ref}
                label="Event Name"
                fullWidth
                size="large"
                variant="standard"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="roles"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <FormLabel component="legend">Active Roles</FormLabel>
                  {ROLES.map((role) => (
                    <FormControlLabel
                      key={role}
                      control={
                        <Switch
                          checked={field.value.indexOf(role) > -1}
                          name={role}
                          onChange={(ev) => {
                            if (field.value.indexOf(role) === -1) {
                              setValue("roles", [...field.value, role]);
                            } else {
                              setValue(
                                "roles",
                                field.value.filter((r) => r !== role)
                              );
                            }
                          }}
                        />
                      }
                      label={role}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            loading={formState.isSubmitting}
            type="submit"
          >
            Create
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

const CREATE_EVENT = gql`
  mutation ($name: String!) {
    event: createEvent(name: $name) {
      id
      name
      created
    }
  }
`;

const ADD_ROLE = gql`
  mutation ($eventId: String!, $role: ROLE!) {
    addRoleTable(eventId: $eventId, role: $role)
  }
`;
